<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    v-if="value > 0"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col"></div>
            <div class="col-auto">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                :aria-label="$t(`orderChange.close`)"
              ></button>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <div class="mb-3">
                <b>{{ $t("textAndFilesModal.files") }}</b>
              </div>
              <div
                class="badge badge-outline mb-2 w-100 d-block text-start"
                v-for="file in files"
                :key="file.id"
              >
                <div class="row">
                  <div class="col text-wrap">
                    <div class="mb-1">{{ file.name }}</div>
                    <span>{{ Math.round(file.size / 1000) / 1000 }} MB</span>
                    <div>{{ file.comment }}</div>
                    <div>{{ formatDate(file.created_at) }}</div>
                  </div>
                  <div class="col-auto">
                    <button
                      class="btn btn-transparent"
                      v-on:click="download(file)"
                    >
                      <base-icon name="download" />
                    </button>
                  </div>
                </div>
              </div>
              <file-pond
                name="file"
                ref="pond"
                label-idle="Drop files here..."
                v-bind:allow-multiple="true"
                :accepted-file-types="data.mime_types"
                v-bind:files="myFiles"
                v-on:init="handleFilePondInit"
                :server="server"
                :credits="false"
              />
            </div>
            <div class="col-9">
              <div class="row">
                <div class="col">
                  <div class="bold-16" v-if="!edit">
                    {{ data.title }}
                  </div>
                  <div v-else class="custom-input whitebg-input mb-2">
                    <label class="form-label bold-12 text-gray visually-hidden"
                      >{{ $t("textAndFilesModal.title") }}</label
                    >
                    <input
                      type="text"
                      v-model="data.title"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-auto">
                  <button class="btn btn-transparent" v-on:click="edit = !edit">
                    <BaseIcon name="edit" />
                  </button>
                </div>
              </div>
              <div v-if="!edit" v-html="data.description"></div>
              <div v-if="edit">
                <WYSIWYG v-model="data.description" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import BaseIcon from "@/components/icons/BaseIcon";
import http from "@/modules/http";
import WYSIWYG from "@/components/inputs/WYSIWYG";
import store from "@/store";
import date from "@/modules/date";
import tenant from "../../modules/tenant";
const bootstrap = require("bootstrap");

// Create component
const FilePond = vueFilePond(FilePondPluginImagePreview);

export default {
  name: "TextAndFilesModal",
  components: { WYSIWYG, BaseIcon, FilePond },
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Number,
  },
  data() {
    return {
      value: this.modelValue,
      myModal: undefined,
      files: [],
      data: {},
      loadTime: true,
      needSave: false,
      saveTime: null,
      edit: false,
      myFiles: [],
      server: {
        process: this.upload,
      },
    };
  },
  watch: {
    data: {
      deep: true,
      handler() {
        if (this.loadTime) {
          return;
        }
        this.needSave = true;
        if (this.saveTime === null) {
          this.saveTime = setTimeout(this.save, 2000);
        }
      },
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  computed: {
    rowId() {
      return this.row.row_key.replace("custom_", "");
    },
  },
  methods: {
    formatDate(val) {
      return date.format(val);
    },
    download(file) {
      window.location = `${process.env.VUE_APP_API}/crm-row-file-download/${file.uuid}?_token=${store.state.token}`;
    },
    downloadZip(file) {
      window.location = `${process.env.VUE_APP_API}/crm-row-zip-file-download/${file.uuid}?_token=${store.state.token}`;
    },
    save() {
      if (this.needSave) {
        this.$emit("change", this.modelValueData);
        this.needSave = false;
        if (this.saveTime !== null) {
          clearInterval(this.saveTime);
          this.saveTime = null;

          const data = {
            crm_custom_field_type_id: this.data.crm_custom_field_type_id,
            title: this.data.title,
            description: this.data.description,
          };

          http.fetch(
            "/crm-custom-field-values/" + this.value,
            data,
            true,
            "PUT"
          );
        }
      }
    },
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
      this.load();
    },
    load() {
      let _this = this;
      this.loadTime = true;
      http.fetch("/crm-custom-field-values/" + this.value).then((data) => {
        this.data = data.data;
        this.files = data.data.files;
        setTimeout(function () {
          _this.loadTime = false;
        }, 400);
      });
    },
    upload(fieldName, file, metadata, load, error, progress, abort) {
      const request = new XMLHttpRequest();
      const formData = new FormData();
      formData.append(fieldName, file, file.name);
      formData.append("crm_custom_field_value_id", this.value);

      request.open("POST", tenant.getApiBaseUrl() + "/crm-row-file-upload");

      request.setRequestHeader("Authorization", "Bearer " + store.state.token);
      request.setRequestHeader("Accept", "application/json");

      request.upload.onprogress = (e) => {
        progress(e.lengthComputable, e.loaded, e.total);
      };

      request.onload = function () {
        if (request.status >= 200 && request.status < 300) {
          let data2 = JSON.parse(request.responseText);

          console.log(data2);

          load(request.responseText);
        } else {
          error("oh no");
        }
      };

      request.send(formData);

      return {
        abort: () => {
          request.abort();
          abort();
        },
      };
    },
    handleFilePondInit: function () {
      console.log("FilePond has initialized");
    },
  },
};
</script>
