<template>
  <span class="px-3" v-if="!edit" v-on:dblclick="edit = true">{{
    modelValue === undefined || modelValue === "" || modelValue === null
      ? " - "
      : enumValue
  }}</span>
  <v-select
    v-else
    v-model="value"
    :options="row.values"
    ref="imp2"
    append-to-body
    taggable
    :reduce="row.selectKey !== undefined ? (a) => a[row.selectKey] : undefined"
    :label="row.label"
    :create-option="(v) => row.createOption(v)"
    v-on:close="focusOut"
    v-on:option:selected="focusOut"
    :dropdown-should-open="() => edit"
  />

  <button
    class="btn btn-transparent btn-sm"
    :title="$t(`textAndFiles.moreInformation`)"
    v-on:click="show"
    v-if="value > 0"
  >
    <BaseIcon name="info-circle" />
  </button>
  <TextAndFilesModal v-model="value" :id="id" :row="row" ref="modal" />
</template>
<script>
import { defineAsyncComponent } from "vue";
import TextAndFilesModal from "@/components/modals/TextAndFilesModal";
const BaseIcon = defineAsyncComponent(() => import("../../icons/BaseIcon.vue"));

export default {
  name: "TextAndFiles",
  components: { TextAndFilesModal, BaseIcon },
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      edit: false,
      value: this.modelValue,
    };
  },
  computed: {
    enumValue() {
      console.log(this.row.values);
      if (this.row.values === undefined || this.row.values === null) {
        return this.modelValue;
      }
      let e =
          this.row.values?.length > 0
              ? this.row.values.find((d) => d[this.row.selectKey] === this.modelValue)
              : this.row.values[this.modelValue];

      console.log(e);
      if (
          e !== null &&
          e !== undefined &&
          Object.keys(e).indexOf(this.row.label) > -1
      ) {
        return e[this.row.label];
      }

      return this.modelValue;
    },
  },
  watch: {
   /* value() {
      this.edit = false;
      this.$emit("update:modelValue", this.value);
      this.$emit("update", this.value);
    },*/
    modelValue() {
      this.value = this.modelValue;
    },
  },
  methods: {
    focusOut() {
      this.edit = false;
      this.$emit("update:modelValue", this.value);
      this.$emit("update", this.value);
    },
    show() {
      this.$refs.modal.showModal();
    },
  },
  emits: ["update:modelValue", "update"],
};
</script>
